import React from "react";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SEO from "../components/seo";
import { Link } from "gatsby-plugin-intl";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "noto-sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  contents: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    minHeight: "760px",
    maxWidth: "820px",

    "& h1": {
      fontSize: "160px",
      color: "#4a4a4a",
    },
    "& h2": {
      fontSize: "40px",
      color: "#858585",
    },
    "& p": {
      fontSize: "20px",
      color: "#9c9c9c",
      marginBottom: "80px",
      textAlign: "center",
    },
  },
  link: {
    textDecoration: "none",
    border: "1px solid #00dd99",
    color: "#00dd99",
    padding: "16px 80px",
    fontSize: "20px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <SEO title="404: Not found" />
      <Container className={classes.contents}>
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>
          The page you are looking for doesn't exist or and other error
          occurred.
          <br />
          Go back, or head over to Hyperconnect.com to choose a new direction.
        </p>
        <Link to="/" className={classes.link}>
          Main Page
        </Link>
      </Container>
    </ThemeProvider>
  );
};

export default NotFoundPage;
